import PersonRounded from '@mui/icons-material/PersonRounded';
import { List, ListItem, ListItemDecorator } from '@mui/joy';
import { Typography } from '@mui/joy';
import { DeleteAccountButton } from 'components/DeleteAccount';
import { Link } from 'components/Link';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { useStore } from 'store/StoreContext';
import { Account } from 'store/domain/account/account';

export const AccountList = observer(() => {
  const { accounts: { sortedAccountList } } = useStore();

  return (
    <List>
      {sortedAccountList
        .filter(({ isDeleted }) => !isDeleted)
        .map(account => <AccountItem key={account.id} account={account} />)}
    </List>
  );
});

export const AccountItem = observer(({ account }: PropsWithChildren<{ account: Account }>) => (
  <ListItem endAction={<DeleteAccountButton account={account} isIconButton />}>
    <ListItemDecorator>
      <PersonRounded />
    </ListItemDecorator>
    <Link to={`/main/account/${account.id}`} underline="none" color="neutral">
      <Typography level="title-sm">
        {account.name}
      </Typography>
    </Link>
  </ListItem>
));
