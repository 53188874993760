import { UpdateTradeSettingsDto } from '@core/models';
import { Box, Button, Card, CardActions, CardOverflow, Divider, FormControl, FormHelperText, FormLabel, Input, Stack, Switch, Typography } from '@mui/joy';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Account, TradeSettings as TradeSettingsModel } from 'store/domain/account/account';
import { FollowedTrader } from 'store/domain/leaderboard/followed-trader';
import { ExceptionManagerButton } from './ExceptionManager';

export type TradeSettingsProp = PropsWithChildren<{ account: Account; trader?: FollowedTrader; tradeSettings?: TradeSettingsModel }>
export type TradeSettingsFormProp = PropsWithChildren<{ tradeSettings: TradeSettingsModel }>

export const TradeSettings = observer(({ account, trader, tradeSettings: tradeSettingsProp }: TradeSettingsProp) => {
  const [tradeSettings, setTradeSettings] = useState(tradeSettingsProp);

  const createTradeSettings = async () => {
    setTradeSettings(await account.createTradeSettings(trader));
  };

  useEffect(() => {
    setTradeSettings(tradeSettingsProp);
  }, [tradeSettingsProp]);

  if (!tradeSettings) {
    return (
      <Card>
        <Box sx={{ mb: 2 }}>
          <Typography level="title-md">Trade settings</Typography>
        </Box>
        <Divider />
        <Button size="md" variant="solid" type="submit" loading={false} onClick={createTradeSettings}>
          Create new trade settings
        </Button>
      </Card>
    );
  }

  return <TradeSettingsForm tradeSettings={tradeSettings} />;
});

export const TradeSettingsForm = observer(({ tradeSettings }: TradeSettingsFormProp) => {
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
  } = useForm<UpdateTradeSettingsDto>({ defaultValues: getSnapshot(tradeSettings) });

  const onSubmit: SubmitHandler<UpdateTradeSettingsDto> = async data => {
    data.risk = Number(data.risk);
    data.maxRisk = Number(data.maxRisk);
    await tradeSettings.update(data);
    console.log('getSnapshot(tradeSettings)', getSnapshot(tradeSettings));
    reset(getSnapshot(tradeSettings));
  };

  useEffect(() => {
    reset(getSnapshot(tradeSettings));
  }, [tradeSettings]);

  const sx = {
    width: 300,
    justifyContent: 'space-between',
    gap: 3,
  };

  return (
    <Card>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography level="title-md">Trade settings</Typography>
        </Box>
        <Divider />
        <Stack direction="row" spacing={4} paddingY={3}>
          <Stack spacing={4} >
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Mirror trader risk</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Take proportionately same risk as lead trader</FormHelperText>
              </div>
              <Controller
                name="useTraderRisk"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Risk</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>For opening a position</FormHelperText>
              </div>
              <Input {...register('risk')} type="number" slotProps={{ input: { step: 0.1 } }} startDecorator={'%'} required sx={{ width: 100 }} />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Max risk</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Maximum total allowable</FormHelperText>
              </div>
              <Input {...register('maxRisk')} type="number" slotProps={{ input: { step: 0.1 } }} startDecorator={'%'} required sx={{ width: 100 }} />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Leverage</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Set leverage explicitly</FormHelperText>
              </div>
              <Input {...register('leverage')} type="number" endDecorator="x" sx={{ width: 100 }} />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Max positions</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Maximum allowable</FormHelperText>
              </div>
              <Input {...register('maxOpenPositionsLimit')} type="number" slotProps={{ input: { step: 0.1 } }} sx={{ width: 100 }} />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Should not open multiple</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Open action cannot open if already running position</FormHelperText>
              </div>
              <Controller
                name="shouldNotOpenMultipleOnSymbol"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Can open</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Is allowed to open new trades</FormHelperText>
              </div>
              <Controller
                name="shouldOpen"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Can close</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Is allowed to close positions</FormHelperText>
              </div>
              <Controller
                name="shouldClose"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Can increase</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Is allowed to increase positions</FormHelperText>
              </div>
              <Controller
                name="shouldIncrease"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Can decrease</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Is allowed to decrease positions</FormHelperText>
              </div>
              <Controller
                name="shouldDecrease"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Safe mode</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Half risk for all trade settings</FormHelperText>
              </div>
              <Controller
                name="isSafeMode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <ExceptionManagerButton tradeSettings={tradeSettings} />
          </Stack>
        </Stack>
        <CardOverflow sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
        >
          <CardActions sx={{
            alignSelf: 'flex-end',
            pt: 2,
            paddingBottom: 0,
          }}
          >
            <Button size="md" variant="solid" type="submit" disabled={!formState.isDirty} loading={tradeSettings.isUpdating}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      </form>
    </Card>
  );
});
