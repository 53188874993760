import { Close } from '@mui/icons-material';
import { Button, Dropdown, Input, ListItemDecorator, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import { TradeSettingsFormProp } from './TradeSettings';
import { useState } from 'react';

export const ExceptionManagerButton = observer(({ tradeSettings, tradeSettings: { exceptionList } }: TradeSettingsFormProp) => {
  const [exceptionInput, setExceptionInput] = useState('');

  const noExceptionsMessage = (
    <>
      <Typography level="title-md" sx={{ padding: 3 }}>There are no excepetions yet.</Typography>
    </>
  );

  const list = exceptionList.map(exception => (
    <MenuItem
      key={exception}
      onClick={() => tradeSettings.removeException(exception)}
      sx={{ justifyContent: 'space-between' }}
    >
      <ListItemDecorator>
        <Close />
      </ListItemDecorator>
      {exception}
    </MenuItem>
  ));

  return (
    <Dropdown onOpenChange={() => setExceptionInput('')}>
      <MenuButton>
        Manage exceptions (
        {exceptionList.length}
        )
      </MenuButton>
      <Menu>
        {exceptionList.length > 0 ? list : noExceptionsMessage}
        <div style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
        }}
        >
          <Input
            value={exceptionInput}
            onChange={({ target }) => {
              // @ts-ignore
              setExceptionInput(target.value);
            }}
            endDecorator={
              <Button onClick={() => {
                tradeSettings.addException(exceptionInput);
                setExceptionInput('');
              }}
              >
                Add
              </Button>
            }
          />
        </div>
      </Menu>
    </Dropdown>
  );
});
